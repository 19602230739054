<template>
  <div class="error_page">
    <div class="form_wrapper" v-if="routeErrorCode == 407 || routeErrorCode == 408 || routeErrorCode == 410">
      <div v-if="routeErrorCode == 410" class="main">
        <p class="title">{{ $t('loginClient.header') }}</p>
        <p v-html="$t('loginClient.invailidRafLink')"></p>
        <a :href="forexTradingAccountLink" class="btn">
          {{ $t('common.button.createLiveAccount') }}
        </a>
      </div>
      <div v-else class="main">
        <p class="title">{{ $t('common.field.hasAccountAlready') }}</p>
        <p v-html="$t('loginClient.existingClientPortalAccess')"></p>
        <el-button class="btn" @click="goLogin">
          {{ $t('common.button.login') }}
        </el-button>
        <p class="forgot_pass" @click="goForgotPass">
          {{ $t('common.button.forgotPw') }}
        </p>
      </div>
    </div>
    <div class="form_wrapper" v-else>
      <p class="title">{{ $t('responseMsg.' + routeErrorCode) }}</p>
      <p>
        <a :href="`https://${url}`">Click here</a>
        to go back to the {{ $config.info.fullName }} homepage, <br />or
        <a :href="'mailto:' + email">send us an email</a>
        to let us know you've encountered an error.
      </p>
      <p class="error_code">error code : {{ routeErrorCode }}</p>
    </div>
  </div>
</template>

<script>

export default {
  components: {},
  data() {
    return {
      url: '',
      email: '',
      mobileNumber: '',
      ctCode: ''
    };
  },
  mounted() {
    const regulator = 'fsa';
    this.url = this.$config.info.getUrl(regulator);
    this.email = this.$config.info.getEmail(regulator);
    this.mobileNumber = this.$route.query.mobile;
    this.ctCode = this.$route.query.countryPhoneCode;
  },
  computed: {
    routeErrorCode() {
      return this.$route.query.code;
    },
    lang() {
      return this.$store.state.common.lang;
    },
    countryCode() {
      return this.$store.state.common.countryCode;
    },
    forexTradingAccountLink() {
      // logic from ACM-7664, ACM-7639
      const LNAGMAP = {
        zh_CN: 'cn',
        fr_FR: 'fr',
        th: 'th',
        de: 'de',
        es: 'es',
        vi: 'vn',
        id: 'id',
        ar: 'ar',
        ko: 'kr',
        ja: 'jp',
        pt: 'pt'
      };
      let preFix = LNAGMAP[this.lang] || '';
      // malay
      if (this.countryCode === '5015') {
        preFix = '';
      }
      // Indonisa
      if (this.countryCode === '6877' && this.lang !== 'id') {
        preFix = '';
      }

      let domain = !preFix ? this.GLOBAL_REFERRAL_SITE : this.GLOBAL_REFERRAL_SITE.replace('www.', '.');
      if (this.countryCode === '5015' || this.countryCode === '6877') {
        domain = this.regulator === 'svg' ? domain.replace('net', 'trading') : domain.replace('com', 'online');
      }

      let languageSuffix = !['th'].includes(this.lang) ? '' : '/' + this.lang;

      return `https://${preFix}${domain}${languageSuffix}/forex-trading-account/`;
    },
  },
  methods: {
    goLogin() {
      this.$router.push('/login')
    },
    goForgotPass() {
      this.$router.push('/forgetPassword')
    },
  }
};
</script>

<style lang="scss" scoped>
// @import '@/assets/css/pages/register/error.scss';
.error_page {
  height: 100%;
  display: flex;
  align-items: center;

  .form_wrapper {
    text-align: center;
    flex: 1;
    margin-top: -80px;

    @include screen-mobile {
      margin-top: -24px;
    }

    p.title {
      font-weight: 700;
      font-size: 24px;
      color: #09090D;
      line-height: 36px;
      margin-bottom: 24px;
    }

    p {
      font-size: 12px;
      line-height: 18px;
      color: $text-secondary;

      a {
        color: $text-link;
      }

      &.error_code {
        margin-top: 24px;
      }
    }

    .btn {
      display: inline-block;
      width: 100%;
      height: 40px;
      font-size: 14px;
      font-weight: 700;
      color: $white;
      border-radius: 8px;
      background: $secondary;
      margin-top: 40px;
    }

    a.btn {
      line-height: 40px;
    }

    .forgot_pass {
      font-size: 14px;
      line-height: 22px;
      color: $primary;
      cursor: pointer;
      text-align: center;
      margin-top: 24px;
    }
  }
}
</style>
